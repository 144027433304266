import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/body/layout';
import Head from '../components/header/head'
import projectsStyles from './projects.module.scss';


const ProjectsPage = () => {
    /* 
    use with CMS

        const data = useStaticQuery(graphql`
        query {
            allContentfulBlogPost(
                sort: {
                    fields:publishedDate,
                    order:DESC
        }
      )
      {
        edges {
          node {
            title
            slug
            publishedDate(formatString:"Do MMMM, YYYY")
          }
        }
    }
    }
    `)
    
        return (
            <Layout>
                <Head title="Projects" />
                <h1>
                    <ol className={projectsStyles.projects}>
                        {data.allContentfulBlogPost.edges.map((edge) => {
                            return (
                                <li className={projectsStyles.project}>
                                    <Link to={`${edge.node.slug}`}>
                                        <h2>{edge.node.title}</h2>
                                        <p>{edge.node.publishedDate}</p>
                                    </Link>
                                </li>
                            )
                        })}
                    </ol>
                </h1>
            </Layout>
        )
    } */


    // use with Markdown Document

    const data = useStaticQuery(graphql`
query {
    allMarkdownRemark {
        edges {
            node {
                frontmatter {
                    title
                    date
                }
                fields{
                    slug
                }
            }
        }
    }
}
`)

    return (
        <Layout>
            <Head title="Projects" />
            <h1>
                <ol className={projectsStyles.posts}>
                    {data.allMarkdownRemark.edges.map((edge) => {
                        return (
                            <li className={projectsStyles.post}>
                                <Link to={`/projects/${edge.node.fields.slug}`}>
                                    <h2>{edge.node.frontmatter.title}</h2>
                                    <p>{edge.node.frontmatter.date}</p>
                                </Link>
                            </li>
                        )
                    })
                    }
                </ol>
            </h1>
        </Layout>
    )
}


export default ProjectsPage;



